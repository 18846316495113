// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("C:\\Users\\mirko.cardoso\\workspace\\sigrid\\website\\sigrid-website-gatsby-public\\src\\pages\\404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-app-architettura-jsx": () => import("C:\\Users\\mirko.cardoso\\workspace\\sigrid\\website\\sigrid-website-gatsby-public\\src\\pages\\app\\architettura.jsx" /* webpackChunkName: "component---src-pages-app-architettura-jsx" */),
  "component---src-pages-app-leggi-jsx": () => import("C:\\Users\\mirko.cardoso\\workspace\\sigrid\\website\\sigrid-website-gatsby-public\\src\\pages\\app\\leggi.jsx" /* webpackChunkName: "component---src-pages-app-leggi-jsx" */),
  "component---src-pages-app-revisione-jsx": () => import("C:\\Users\\mirko.cardoso\\workspace\\sigrid\\website\\sigrid-website-gatsby-public\\src\\pages\\app\\revisione.jsx" /* webpackChunkName: "component---src-pages-app-revisione-jsx" */),
  "component---src-pages-app-rischi-jsx": () => import("C:\\Users\\mirko.cardoso\\workspace\\sigrid\\website\\sigrid-website-gatsby-public\\src\\pages\\app\\rischi.jsx" /* webpackChunkName: "component---src-pages-app-rischi-jsx" */),
  "component---src-pages-app-sci-jsx": () => import("C:\\Users\\mirko.cardoso\\workspace\\sigrid\\website\\sigrid-website-gatsby-public\\src\\pages\\app\\sci.jsx" /* webpackChunkName: "component---src-pages-app-sci-jsx" */),
  "component---src-pages-index-jsx": () => import("C:\\Users\\mirko.cardoso\\workspace\\sigrid\\website\\sigrid-website-gatsby-public\\src\\pages\\index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-obiettivi-jsx": () => import("C:\\Users\\mirko.cardoso\\workspace\\sigrid\\website\\sigrid-website-gatsby-public\\src\\pages\\obiettivi.jsx" /* webpackChunkName: "component---src-pages-obiettivi-jsx" */),
  "component---src-pages-pianificazione-jsx": () => import("C:\\Users\\mirko.cardoso\\workspace\\sigrid\\website\\sigrid-website-gatsby-public\\src\\pages\\pianificazione.jsx" /* webpackChunkName: "component---src-pages-pianificazione-jsx" */),
  "component---src-pages-progetto-jsx": () => import("C:\\Users\\mirko.cardoso\\workspace\\sigrid\\website\\sigrid-website-gatsby-public\\src\\pages\\progetto.jsx" /* webpackChunkName: "component---src-pages-progetto-jsx" */),
  "component---src-pages-risultati-jsx": () => import("C:\\Users\\mirko.cardoso\\workspace\\sigrid\\website\\sigrid-website-gatsby-public\\src\\pages\\risultati.jsx" /* webpackChunkName: "component---src-pages-risultati-jsx" */),
  "component---src-pages-team-jsx": () => import("C:\\Users\\mirko.cardoso\\workspace\\sigrid\\website\\sigrid-website-gatsby-public\\src\\pages\\team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */)
}

